export default {
  "classId": "classID",
  "dataType": "datatype",
  "itemId": "itemID",
  "strokeDashArray": "strokeDasharray",
  "strokeDashOffset": "strokeDashoffset",
  "strokeLineCap": "strokeLinecap",
  "strokeLineJoin": "strokeLinejoin",
  "strokeMiterLimit": "strokeMiterlimit",
  "typeOf": "typeof",
  "xLinkActuate": "xlinkActuate",
  "xLinkArcRole": "xlinkArcrole",
  "xLinkHref": "xlinkHref",
  "xLinkRole": "xlinkRole",
  "xLinkShow": "xlinkShow",
  "xLinkTitle": "xlinkTitle",
  "xLinkType": "xlinkType",
  "xmlnsXLink": "xmlnsXlink"
}
;